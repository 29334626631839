@import 'index';

*{
  font-style: normal!important;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body{
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: AndSansRegular, Helvetica, Arial, Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;

  font-size: calc(0.5vw + 0.75vh);

  // background: $black;

  @media screen and (min-width:1024px){
    
    font-size: 12px;
  }

  @media (orientation: landscape) and (min-width:1024px) and (max-width:1366px) { 
    font-size: 10px;
  }

  &.-simple{
    background: $white;
  }

  &.-lock{
    height: 100%;
    overflow: hidden;
  }
}

.loading{
  width: 100%;
  height: 100vh;
  background: $black;
}

#ie{
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: $black;

  &.-simple{
    background: $white;
  }

  &.-spacing{
    padding: 0 3vh;

    @media screen and (min-width: 1025px){
      padding: 0 30px;
    }
  }
}

 button,
 input,
 optgroup,
 select,
 textarea {
   font-family: inherit;
   font-size: 100%;
   line-height: 1.15;
   margin: 0;
 }
 
 button,
 input {
   overflow: visible;
 }
 
 button,
 select {
   text-transform: none;
 }
 
 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button;
 }

 *:focus {
  outline: none;
}

.preloader{
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  @include hide(0.7s);

  &.-active{
    @include show();
  }

  &-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-title{
    font-family: "Pepeha Display";
    color: white;
    transform: translateY(2vh);
    font-size: 4.5em;
    text-transform: uppercase;
    letter-spacing: .2em;
    margin: 0;

    @include hide(0.4s);
    transition: opacity 0.4s, transform 1.4s;

    &.-active{
      @include show();

      transform: translateY(0%);
      transition: 0.7s;
    }
  }

  &-dash{
    color: white;
    transform: translateY(2vh);
    font-size: 2em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin: 0;
    margin-top: 0.5em;

    @include hide(0.4s);
    transition: opacity 0.4s, transform 1.4s;

    &.-active{
      @include show();

      transform: translateY(0%);
      transition: 0.7s;
      transition-delay: 0.2s;
    }
  }

  &-explanation{
    color: white;
    transform: translateY(2vh);
    font-size: 2em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin: 0;
    margin-top: 1em;

    @include hide(0.4s);
    transition: opacity 0.4s, transform 1.4s;

    &.-active{
      @include show();

      transform: translateY(0%);
      transition: 0.7s;
      transition-delay: 0.4s;
    }
  }
}


.mobileAdjustBox{
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  transition: height 0.1s; 
  
  transform: translateX(-50%);
  overflow-y: hidden;
  overflow-x: hidden;

  &.-zoomed{
    left: 0;
    transform: translateX(0);
    z-index: 8;
  }
}

.landing{
  position: absolute;
	width: 100%;
	z-index: 9;
	top: 0;
	left: 0;
  background: $black;

  @include hide(0.7s);

  &.-active{
    @include show();
  }
  
  &-img{
    max-width: 1920px;
    width: 100%;
    cursor: pointer;
  }
}

.explanation{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $black;
  z-index: 9;

  @include hide(0.7s);

  &.-active{
    @include show();
  }

  &-text{
    width: 60%;
    font-size: 2.5em;
    line-height: 1.4em;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    color: $white;
  }
}

.stageCantFind{
  position: absolute;
  bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
	height: 4vh;
	background: $white;
  z-index: 4;

  @include hide(0.7s);

  &.-active{
    @include show();
  }
  
  &-text{
    color: $black;
    margin: 0;
    font-size: 0.8em;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    @include hide(0.7s);

    &.-active{
      @include show();
    }

    .-simple &{
      color: $black;
    }
  }

  &-link{
    color: $beige;
    font-family: AndSansBold;
  }

  &-icon{
    width: 2vw;
    transform: rotate(180deg);
  }
}

.progress{
  position: absolute;
	top: 50%;
	left: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
  width: 13em;
  height: 100%;
	transform: translateY(-50%);
	background: $white;
  z-index: 4;
  
  @include hide(0.7s);

  &.-active{
    @include show();
  }
  
  &-container{
    padding-left: 2em;
  }

  &-stage{
    position: relative;
    border-bottom: solid 0.5px $beige;

    &:last-child{
      border-bottom: none;
    }
  }

  &-item{
    max-width: 14em;
    text-overflow: ellipsis;
    $white-space: nowrap;
    overflow: hidden;

    color: $beige;
    text-transform: uppercase;
    font-size: 0.7em;
    letter-spacing: .2em;
    margin-left: 0;
    margin-bottom: 0.5em;
    transition: color 0.7s;
    user-select: none;
    pointer-events: none;

    &.-active{
      color: $black;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  &-text{
    color: inherit;
    text-transform: inherit;
    font-size: inherit;
    letter-spacing: inherit;
  }
}

.rightBorder{
  position: absolute;
	right: 0;
	top: 0;
  width: 4em;
  height: 100%;
	background: $white;
	display: flex;
	justify-content: center;
	align-items: center;
  z-index: 4;

  @include hide(0.7s);

  &.-active{
    @include show();
  }
  
  &-img{
    width: 2.6em;
  }
}

.store{
  position: absolute;
	top: 0;
	left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9;

  @include hide(1.4s);

  &.-active{
    @include show();
  }
  
  &-preview{
    position: absolute;
    top: 4vh;
    left: 4vh;
    width: 92vh;
    height: 92vh;
    // background: url("Photo_frame-center.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    & .pepehaSvg-container{
      position: absolute;
      left: 50%;
      top: 50%;
      transform-origin: top left;
      color: $beige;
      margin: 0;
    }

    & .pepehaSvg-create{
      display: none;
    }
  }

  &-ui{
    position: absolute;
    top: 4vh;
    right: 4vh;
    width: calc(100% - 100vh);
    width: calc(100% - (var(--vh, 1vh) * 100));
    height: 92vh;
    // background: url("Print_copy.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.pepeha-svg{
  display: none;
}

.comingSoon{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.75);

  @include hide(0.7s);

  &.-active{
    @include show();
  }

  &-content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: calc(80% - 4vw - 4vh);
    height: calc(80% - 4vw - 4vh);
    max-width: 600px;
    max-height: 700px;
    padding: calc(2vw + 2vh);
    background: $white;

    @media screen and (min-width: 1025px){
      align-items: center;
    }
  }

  &-close{
    position: absolute;
    right: 1.5vh;
    top: 1.3vh;
    width: 4vh;
    height: 4vh;
    fill: $black;
    cursor: pointer;
    z-index: 3;
  }

  &-title{
    font-size: 6em;
    letter-spacing: 0.12em;
    font-family: "Pepeha Display";
    margin: 0;
    margin-bottom: 2vh;
  }

  &-paragraph{
    font-size: 2.25em;
    letter-spacing: 0.12em;
    margin: 0;
    margin-bottom: 2vh;
    max-width: 768px;

    @media screen and (min-width: 1025px){
      text-align: center;
    }
  }

  &-button{
    position: absolute;
    bottom: calc(2vw + 2vh);
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
    background: $beige;
    font-family: andSansBold;
    color: $black;
    font-size: 2.25em;
    letter-spacing: 0.12em;
    padding: 1em 2.5em;
  }
}

.simpleBack{
  position: absolute;
  left: 1.5vh;
  top: 1.3vh;
	width: 4vh;
	height: 4vh;
  z-index: 9;
  cursor: pointer;

  @include hide(0.7s);

  &.-active{
    @include show();
  }

  & circle, & polyline{
    fill:transparent;
    stroke:$white;
    stroke-width:1.58px;
    stroke-miterlimit:10;
    transition: stroke 0.7s;
  }

  &.-black{
    & circle, & polyline{
      stroke:$black;
    }
  }
}

.simpleRefresh{
  position: absolute;
  left: 1.5vh;
  top: 1.4vh;
	width: 4vh;
	height: 4vh;
  z-index: 9;
  cursor: pointer;
  @include hide(0.7s);

  &.-active{
    @include show();
  }

  &-icon{
    & .cls-1{
      fill:none;
      stroke:#000;
      stroke-miterlimit:10;
      stroke-width:1.58px;
    }
  }
}

#rasterise{
  display: none;
}

.col{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.-topMargin{
    margin-top: 10vh;
  }

  & > *{
    @media screen and (min-width: 1025px){
      padding: 0 2vw;
    }

    &:first-child{
      padding-left: 0;
    }

    &:last-child{
      padding-right: 0;
    }
  }

  

  &-12{
    width: calc(100% * (12/12) - 4.1vw);
  }

  &-10{
    width: calc(100% * (10/12) - 4.1vw);
  }

  &-9{
    width: calc(100% * (9/12) - 4.1vw);
  }

  &-8{
    width: calc(100% * (8/12) - 4.1vw);
    position: relative;
    z-index: 2;
  }

  &-7{
    width: calc(100% * (7/12) - 4.1vw);
  }

  &-6{
    width: calc(100% * (6/12) - 4.1vw);
  }

  &-5{
    width: calc(100% * (5/12) - 4.1vw);
  }

  &-4{
    width: calc(100% * (4/12) - 4.1vw);
    position: relative;
    z-index: 2;
  }

  &-3{
    width: calc(100% * (3/12) - 4.1vw);
    position: relative;
    z-index: 2;
  }

  &-2{
    width: calc(100% * (2/12) - 4.1vw);
  }

  &-1{
    width: calc(100% * (1/12) - 4.1vw);
  }

  @media screen and (max-width:1024px){
    &-s{
      &-0{
        display: none;
      }

      &-1{
        width: calc(100% * (1/12) - 4.1vw);
      }

      &-6{
        width: calc(100% * (6/12) - 4.1vw);
      }

      &-8{
        width: calc(100% * (8/12) - 4.1vw);
      }

      &-10{
        width: calc(100% * (10/12) - 4.1vw);
      }

      &-12{
        width: calc(100% * (12/12) - 4.1vw);
      }
    }
  }
}

.ctaButton{
  &-button{
    position: relative;
    width: 100%;
    height: 100%;
  }

  &-ripple{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $beige;
    transform: scale(1);
    animation: ripple 5s infinite;

    .-black &{
      background: $black;
    }
  }

  & .cls-1{
    fill: $beige;
  }
}

.js-cm-submit-button{
  display: none;
}

.-hidden{
  display: none;
  user-select: none;
	pointer-events: none;
}

.thanks{
  position: fixed;
  width: 100%;
	height: 100%;
	background: $black;
	z-index: 999;
	display: flex;
	justify-content: center;
  align-items: center;
  
  @include hide(0.7s);

  &.-active{
    @include show();
  }

  & .navDesktop{
    width: calc(100% - 60px);
	  max-width: 1330px;
  }

  &-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &-top{
    height: calc((100% - 7vh) / 3);
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-middle{
    height: calc((100% - 7vh) / 3);
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $beige;
  }

  &-bottom{
    height: calc((100% - 7vh) / 3);
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
  }
  
  &-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 600px;
    width: 80%;
  }

  &-title{
    color: $white;
    font-size: 3em;
    letter-spacing: 0.12em;
    line-height: 1.3em;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }

  &-paragraph{
    color: $black;
    margin: 0;
    font-size: 2.25em;
    margin-top: 1em;

    .thanks-top &{
      color: $white;
    }
  }

  &-insta{
    display: flex;
    flex-direction: column;
    margin-top: 6em;
  }

  &-instaLink{
    width: 3em;
    height: 3em;
    margin-top: 1.5em;

    & svg{
      fill: $white;
    }
  }

  &-dontForget{
    color: $white;
    font-size: 1.75em;
	  margin: 0;
  }

  &-buttons{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10vh;
  }

  &-button{
    text-decoration: none;
    font-family: andSansBold;
    background: transparent;
    color: $black;
    border: solid 2px $black;
    font-size: 2em;
    letter-spacing: .12em;
    padding: 1em 2.5em;
    margin: 0.5em 0;
    text-align: center;
    text-transform: uppercase;
    width: calc(100% - 5em - 4px);

    &.-ios{
      background: $black;
      color: $white;
      border: solid 2px $white;
    }
  }
}



.mapboxgl-ctrl-top-right{
  top: initial !important;
  bottom: 7vh!important;
  right: 1vw! important;
}

.agreeMessage{
  margin: 2vh 0;
  font-size: 12px;
  letter-spacing: 0.05em;
  width: 100%;
  
  & a{
    color: $black;
  }
}

.-nextText{
  padding: 1.8em;
  text-decoration: none;

  & span{
    font-size: 1.6em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-decoration: none;
    font-family: andSansBold;
    color: $black;
    border-bottom: 0.1em solid $black;
    cursor: pointer;

    @media screen and (max-width: 1024px){
      font-size: 1.8em;
    }
  }
}

.soundIcon{
  height: 1em;
  padding-left: 0.5em;
	transform: translateY(15%);

  & path{
    fill: #DCD4C5;
  }
}

.-para1, p.-para1{
  font-size: 1.7em;
	letter-spacing: .05em;
	line-height: 1.5em;
}

.mapSvg{
  fill: $beige;
}

.previewSvg{
  fill: $beige;
}